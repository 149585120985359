import revive_payload_client_4sVQNw7RlN from "C:/xampp/htdocs/node/modify-today/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "C:/xampp/htdocs/node/modify-today/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/xampp/htdocs/node/modify-today/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/xampp/htdocs/node/modify-today/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/xampp/htdocs/node/modify-today/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import components_plugin_KR1HBZs4kY from "C:/xampp/htdocs/node/modify-today/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/xampp/htdocs/node/modify-today/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_hwlJ5PmHMh from "C:/xampp/htdocs/node/modify-today/node_modules/nuxt-lazy-load/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "C:/xampp/htdocs/node/modify-today/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import jquery_SqE5xeO2Mp from "C:/xampp/htdocs/node/modify-today/plugins/jquery.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_hwlJ5PmHMh,
  chunk_reload_client_UciE0i6zes,
  jquery_SqE5xeO2Mp
]