import { default as cataloguey1eqI1IB0VMeta } from "C:/xampp/htdocs/node/modify-today/pages/catalogue.vue?macro=true";
import { default as indexPU2NT56V4IMeta } from "C:/xampp/htdocs/node/modify-today/pages/index.vue?macro=true";
import { default as luxury_45itemjeIRrTsgxQMeta } from "C:/xampp/htdocs/node/modify-today/pages/luxury-item.vue?macro=true";
import { default as menuz1PBzf7xG1Meta } from "C:/xampp/htdocs/node/modify-today/pages/menu.vue?macro=true";
import { default as promotionmPDSgDIOrUMeta } from "C:/xampp/htdocs/node/modify-today/pages/promotion.vue?macro=true";
import { default as tncQdxERgb2V9Meta } from "C:/xampp/htdocs/node/modify-today/pages/tnc.vue?macro=true";
import { default as welcome_45goldlOGJpWhJKAMeta } from "C:/xampp/htdocs/node/modify-today/pages/welcome-gold.vue?macro=true";
export default [
  {
    name: cataloguey1eqI1IB0VMeta?.name ?? "catalogue",
    path: cataloguey1eqI1IB0VMeta?.path ?? "/catalogue",
    meta: cataloguey1eqI1IB0VMeta || {},
    alias: cataloguey1eqI1IB0VMeta?.alias || [],
    redirect: cataloguey1eqI1IB0VMeta?.redirect,
    component: () => import("C:/xampp/htdocs/node/modify-today/pages/catalogue.vue").then(m => m.default || m)
  },
  {
    name: indexPU2NT56V4IMeta?.name ?? "index",
    path: indexPU2NT56V4IMeta?.path ?? "/",
    meta: indexPU2NT56V4IMeta || {},
    alias: indexPU2NT56V4IMeta?.alias || [],
    redirect: indexPU2NT56V4IMeta?.redirect,
    component: () => import("C:/xampp/htdocs/node/modify-today/pages/index.vue").then(m => m.default || m)
  },
  {
    name: luxury_45itemjeIRrTsgxQMeta?.name ?? "luxury-item",
    path: luxury_45itemjeIRrTsgxQMeta?.path ?? "/luxury-item",
    meta: luxury_45itemjeIRrTsgxQMeta || {},
    alias: luxury_45itemjeIRrTsgxQMeta?.alias || [],
    redirect: luxury_45itemjeIRrTsgxQMeta?.redirect,
    component: () => import("C:/xampp/htdocs/node/modify-today/pages/luxury-item.vue").then(m => m.default || m)
  },
  {
    name: menuz1PBzf7xG1Meta?.name ?? "menu",
    path: menuz1PBzf7xG1Meta?.path ?? "/menu",
    meta: menuz1PBzf7xG1Meta || {},
    alias: menuz1PBzf7xG1Meta?.alias || [],
    redirect: menuz1PBzf7xG1Meta?.redirect,
    component: () => import("C:/xampp/htdocs/node/modify-today/pages/menu.vue").then(m => m.default || m)
  },
  {
    name: promotionmPDSgDIOrUMeta?.name ?? "promotion",
    path: promotionmPDSgDIOrUMeta?.path ?? "/promotion",
    meta: promotionmPDSgDIOrUMeta || {},
    alias: promotionmPDSgDIOrUMeta?.alias || [],
    redirect: promotionmPDSgDIOrUMeta?.redirect,
    component: () => import("C:/xampp/htdocs/node/modify-today/pages/promotion.vue").then(m => m.default || m)
  },
  {
    name: tncQdxERgb2V9Meta?.name ?? "tnc",
    path: tncQdxERgb2V9Meta?.path ?? "/tnc",
    meta: tncQdxERgb2V9Meta || {},
    alias: tncQdxERgb2V9Meta?.alias || [],
    redirect: tncQdxERgb2V9Meta?.redirect,
    component: () => import("C:/xampp/htdocs/node/modify-today/pages/tnc.vue").then(m => m.default || m)
  },
  {
    name: welcome_45goldlOGJpWhJKAMeta?.name ?? "welcome-gold",
    path: welcome_45goldlOGJpWhJKAMeta?.path ?? "/welcome-gold",
    meta: welcome_45goldlOGJpWhJKAMeta || {},
    alias: welcome_45goldlOGJpWhJKAMeta?.alias || [],
    redirect: welcome_45goldlOGJpWhJKAMeta?.redirect,
    component: () => import("C:/xampp/htdocs/node/modify-today/pages/welcome-gold.vue").then(m => m.default || m)
  }
]